<template>
  <div class="container-fluid">
    <div class="row">
    </div>
    <div class="row">
      <div class="question-list-name blue">
        <p class="mb-0">Audience Strategy</p>
      </div>
      <template v-for="questionGroup in questionData.questionLists[0].questionGroups">
        <question-group color="blue" @answered="setAnswer" @commented="setComment" :questionGroup="questionGroup" :key="questionGroup.questionGroupNumber" :highlightIncomplete="highlightIncomplete" ></question-group>
      </template>
    </div>

    <div class="row">
      <div class="question-list-name green">
        <p class="mb-0">Marketing Analytics</p>
      </div>
      <template v-for="questionGroup in questionData.questionLists[1].questionGroups">
        <question-group color="green" @answered="setAnswer" @commented="setComment" :questionGroup="questionGroup" :key="questionGroup.questionGroupNumber" :highlightIncomplete="highlightIncomplete" ></question-group>
      </template>
    </div>

    <div class="row">
      <div class="question-list-name yellow">
        <p class="mb-0">Data Science & MarTech</p>
      </div>
      <template v-for="questionGroup in questionData.questionLists[2].questionGroups">
        <question-group color="yellow" @answered="setAnswer" @commented="setComment" :questionGroup="questionGroup" :key="questionGroup.questionGroupNumber" :highlightIncomplete="highlightIncomplete" ></question-group>
      </template>
    </div>

    <div class="row">
      <hr class="mb-4">
      <div class="col-12 text-center pb-3" style="min-height: 200px" v-if="submitted">
        <p class="mt-4 text-success" style="font-size: 20px"><strong>Responses submitted.</strong><br><br>Please go to the <router-link class="text-success" to="/reports">Reports</router-link> page to download your report</p>
      </div>
      <div v-else class="col-12 text-center pb-3" style="min-height: 200px">
        <button v-if="submitting" class="btn btn-lg btn-mediacom"><h4 class="mb-0 font-weight-bold">Submitting...</h4></button>
        <button v-else class="btn btn-lg btn-mediacom" @click="submitAnswers"><h4 class="mb-0 font-weight-bold">Submit Answers</h4></button>
        <p class="mt-4 text-danger" style="font-size: 20px" v-if="showErrorPrompt">Please complete all questions</p>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionGroup from '@/components/QuestionGroup.vue'
import questionData from '../data/questionData.json'
import axios from "axios";

export default {
  name: "FormContainer",
  components: {
    QuestionGroup
  },
  props: {
    selectedCompany: String,
    selectedCompanyId: String,
    selectedProduct: String,
    selectedMarket: Object,
    selectedCategory: String,
    selectedIndustry: String,
    selectedSubIndustry: String
  },
  data() {
    return {
      activeQuestionList: 'audienceUnderstanding',
      submitting: false,
      questionData: questionData,
      highlightIncomplete: false,
      showErrorPrompt: false,
      formResponses: [],
      submitted: false
    }
  },
  methods: {
    refreshToken() {
      const refresh = localStorage.getItem("refresh")
      const refreshUrl = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/token/refresh/`
      const payload = {"refresh": refresh.substring(1, refresh.length - 1)}
      return axios.post(refreshUrl, payload).then((response) => {
        this.$store.commit("updateToken", response.data.access);
        this.$store.commit("updateRefresh", response.data.refresh);
        this.submitAnswers()
      }).catch((error) => {
        console.log(error);
        this.$emit('connection-lost')
      })
    },
    setAnswer(question, value) {
      const response = {
        question_number: question,
        answer: Number(value),
        comment: ''
      }
      if (this.formResponses.find(x => x.question_number === question)) {
        const answerIndex = this.formResponses.findIndex(x => x.question_number === question)
        this.formResponses[answerIndex] = {
          question_number: question,
          answer: Number(value),
          comment: ''
        }
      } else {
        this.formResponses.push(response)
      }
    },
    setComment(question, value, comment) {
      const response = {
        question_number: question,
        answer: Number(value),
        comment: comment
      }
      if (this.formResponses.find(x => x.question_number === question)) {
        const answerIndex = this.formResponses.findIndex(x => x.question_number === question)
        this.formResponses[answerIndex] = {
          question_number: question,
          answer: Number(value),
          comment: comment
        }
      } else {
        this.formResponses.push(response)
      }
    },
    submitAnswers() {
      if (this.totalQuestions === this.completedQuestions) {
        this.submitting = true
        // const user_id = localStorage.getItem("user_id")
        if (!this.selectedSubIndustry) {
          this.selectedSubIndustry = 'none'
        }
        const submittedResponse = {
          // "user": Number(user_id),
          "ghc_id": this.selectedCompanyId,
          "ghc_name": this.selectedCompany,
          "region": this.selectedMarket.region,
          "country": this.selectedMarket.country,
          "industry": this.selectedIndustry,
          "sub_industry": this.selectedSubIndustry,
          "advertiser_product_name": this.selectedProduct === 'none' ? this.selectedProduct : this.selectedProduct,
          'answers': this.formResponses
        }
        const url = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/responses/`
        const token = localStorage.getItem("token")
        axios.post(url, submittedResponse, {headers: {Authorization: `Bearer ${JSON.parse(token)}` }}).then(() => {
          this.submitted = true
          this.submitting = false
        }).catch(error => {
          if (error.response.status === 401) {
            this.refreshToken()
          }
        })
      } else {
        this.highlightIncomplete = true
        this.showErrorPrompt = true
        this.submitting = false
      }
    },
    getListName() {
      if (this.activeQuestionList === 'audienceUnderstanding') {
        return 'Audience Understanding'
      } else if (this.activeQuestionList === 'marketingPerformanceMeasurement') {
        return 'Audience Understanding'
      } else {
        return 'Applied DataScience'
      }
    }
  },
  computed: {
    totalQuestions() {
      // let counter = 0;
      // for (let i = 0; i < 2; i++) {
      //   const totalQuestionGroups = this.questionData.questionLists[0].length
      //   for (let i = 0; i < totalQuestionGroups; i++) {
      //     counter += this.questionData.questionLists.questionGroups[i].questions.length
      //   }
      // }
      // return counter
      return 19
    },
    completedQuestions() {
      return this.formResponses.length
    }
  }
}
</script>

<style scoped>
.question-list-name {
  padding: 0.5em 1em;
  color: white;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  margin: 0em;
  font-size: 22px;
}

.blue {
  background: #07859f;
}

.green {
  background: #5ca101;
}

.yellow {
  background: #e57505;
}

.btn-mediacom {
  color: #fff;
  background-color: #f2005a;
  border-color: #f2005a;
}

.btn-mediacom:hover {
  color: #fff;
  background-color: #ce024e;
  border-color: #ce024e;
}
</style>