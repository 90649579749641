import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import 'vue-search-select/dist/VueSearchSelect.css'
import JsonCSV from 'vue-json-csv'
import vSelect from 'vue-select'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Multiselect from 'vue-multiselect'
import OktaVue from '@okta/okta-vue'
import { oktaAuth } from './okta';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueCookies from 'vue-cookies';
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-slider-component/theme/default.css'
import 'vue-select/dist/vue-select.css';
import '../src/css/custom.css';

Vue.use(require('vue-moment'));

Vue.component('multiselect', Multiselect)
Vue.component('v-select', vSelect)
Vue.component('downloadCsv', JsonCSV)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCookies);
Vue.use(OktaVue, { 
  oktaAuth,
  onAuthRequired: () => {
    router.push('/login')
  },
  onAuthResume: () => {
    router.push('/login')
  }
})
Vue.component('VueSlider', VueSlider)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
