<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  data: function () {
    return { authenticated: false }
  },
  async created () {
    await this.isAuthenticated()
    this.$auth.authStateManager.subscribe(this.isAuthenticated)
  },
  watch: {
    // Everytime the route changes, check for auth status
    '$route' (to, from) {
      console.log('Route changed from ' + from.path + ' to ' + to.path); 
    } 
  },
  methods: {
    async isAuthenticated () {
      this.authenticated = await this.$auth.isAuthenticated()
    },
    async logout () {
      await this.$auth.signOut()
    }
  }
}
</script>


<style lang="scss">
html {
  height: 100%;
  background: #edf2f3;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #edf2f3;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#nav {
  padding: 10px;

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;

    &.router-link-exact-active {
      background: #f2005a;
      color: white;
      text-decoration: none;
    }
  }
}

.connection-lost {
  bottom: 0;
  background: #ff5722;
  width: 100%;
  padding: 0.2em;
  text-align: center;
  color: white;
  font-size: 2em;
}

.connection-lost span {
  font-size: 18px;
}

.connection-lost .logout-link {
  text-decoration: underline;
  cursor: pointer;
}

.nav-link {
  display: inline-block !important;
  background: #FAFAFA;
  margin: 0 5px;
  border-radius: 5px;
}

.nav-logout {
  display: inline-block !important;
  margin: 0 5px;
  border-radius: 5px;
  color: #f2005a;
  font-weight: bold;
}

.nav-logout:hover {
  cursor: pointer;
}

.nav-right {
  text-align: right !important;
}

nav div a { 
  margin-right: 10px 
}

</style>
