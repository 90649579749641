<template>
  <div class="row mx-0 my-3 px-3">
    <div class="col-12">
      <div class="bg-white table-container">
        <div class="row">
          <div class="col-6">
            <h5 class="fw-bolder">Generated Reports</h5>
          </div>
          <div class="col-6 text-right">
            <a class="btn-mediacom btn-sm border-0" href="questions_reference.pptx" download>Download Questions</a>
          </div>
        </div>
        <b-row>
          <b-col lg="4" sm="12" class="my-1">
            <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="8" class="my-1 per-page-select">
            <b-form-group
                label="Reports per page"
                label-for="per-page-select"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
            >
              <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  class="p-1 mt-1"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-table
            :busy="!reports"
            :items="reports"
            :filter="filter"
            :current-page="currentPage"
            :per-page="perPage"
            small
            striped
            hover
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            responsive>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(created)="data">
            <p class="text-nowrap m-0">
              {{data.item.created | moment("DD-MM-YYYY")}}
            </p>
          </template>
          <template #cell(user)="data">
            {{data.item.user.email}}
          </template>
          <template #cell(region)="data">
            {{data.item.region.toUpperCase() }} - {{data.item.country }}
          </template>
          <template #cell(download-ppt)="data">
            <b-button v-if="downloading === data.item.id" size="sm" class="btn-mediacom download-btn">Preparing download...</b-button>
            <b-button v-else size="sm" class="btn-mediacom download-btn" @click="downloadPPT(data)">Download PPT</b-button>
          </template>
        </b-table>
        <div class="col-5 m-auto mt-5">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0 mediacom-pagination"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    reports: Array
  },
  data() {
    return {
      downloading: false,
      selectedPPT: null,
      sortBy: 'created',
      sortDesc: true,
      filter: null,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 50, 100],
      fields: [
        {key: 'created', sortable: true, label: 'Created', tdClass: 'report-table-cell'},
        {key: 'id', sortable: true, label: 'Report ID', tdClass: 'report-table-cell'},
        {key: 'user', sortable: true, label: 'Created By', tdClass: 'report-table-cell'},
        {key: 'ghc_id', sortable: true, label: 'GHC ID', tdClass: 'report-table-cell'},
        {key: 'ghc_name', sortable: true, label: 'Company', tdClass: 'report-table-cell'},
        {key: 'industry', sortable: true, tdClass: 'report-table-cell'},
        {key: 'region', sortable: true, label: 'Region & Country', tdClass: 'report-table-cell'},
        {key: 'download-csv', sortable: false, label: '', tdClass: 'report-table-cell'},
        {key: 'download-ppt', sortable: false, label: '', tdClass: 'report-table-cell'}
      ]
    }
  },
  methods: {
    getData() {
      return this.reports
    },
    toggleBusy() {
      this.isBusy = !this.isBusy
    },
    downloadPPT(data) {
      this.downloading = data.item.id
      const url = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/responses/${data.item.id}/download/`
      const token = localStorage.getItem("token")
      return axios.get(url, {headers: {Authorization: `Bearer ${JSON.parse(token)}`}}).then((response) => {
        window.location = response.data.download_url
        this.downloading = null
      }).catch(error => {
        console.log(error);
        this.downloading = null
      })
    },
    downloadCSV(data) {
      const flatArray = {
        "id": data.item.id,
        "user": data.item.user,
        "ghc_id": data.item.ghc_id,
        "ghc_name": data.item.ghc_name,
        "industry": data.item.industry,
        "sub_industry": data.item.sub_industry,
        "region": data.item.region
      }
      for (let i = 0; i < this.companyData.data.length; i++) {
        const company = {
          "value": {
            "name": this.companyData.data[i].name,
            "id": this.companyData.data[i].id
          },
          "text": this.companyData.data[i].name
        }
        flatArray.push(company)
      }
      return flatArray
    }
  },
  computed: {
    totalRows() {
      return this.reports.length
    }
  }
}
</script>

<style lang="scss">

.text-right {
  text-align: right !important;
}

th {
  white-space: nowrap;
  padding: 10px 20px !important;
}

.btn-mediacom {
  color: #fff !important;
  text-decoration: none !important;
  background-color: #f2005a !important;
  border-color: #f2005a !important;
}

.btn-mediacom:hover {
  color: #fff;
  background-color: #ce024e;
  border-color: #ce024e;
}


.sr-only {
  display: none;
}

.table-container {
  margin-top: 1.5em;
  border-radius: 5px;
  background: white;
  padding: 2em;
}

.table-count {
  text-align: right !important;
}

.per-page-select {
  text-align: right !important;
}

.mediacom-pagination .page-item.active .page-link {
  background-color: #f2005a;
  border-color: #f2005a;
  color: white;
}

.mediacom-pagination .page-link:not(.active) {
  color: #f2005a;
}

.download-btn {
  min-width: 160px;
}

.report-table-cell {
  vertical-align: middle;
}

</style>