import OktaSignIn from '@okta/okta-signin-widget'
import { OktaAuth } from '@okta/okta-auth-js'

let org_url = process.env.VUE_APP_OKTA_ORG_URL
let issuer = process.env.VUE_APP_OKTA_ISSUER
let client_id = process.env.VUE_APP_OKTA_CLIENT_ID
let idp_embed_link = process.env.VUE_APP_OKTA_IDP_EMBED_LINK

const oktaSignIn = new OktaSignIn({
  baseUrl: org_url,
  clientId: client_id,
  redirectUri: 'https://audit-tool-331011.web.app/login/callback',
  authParams: {
    pkce: false,
    issuer: issuer,
    display: 'page',
    scopes: ['openid', 'profile', 'email']
  },
  features: {
    idpDiscovery: true
  },
  idpDiscovery : {
    requestContext: idp_embed_link
}
});

const oktaAuth = new OktaAuth({
  issuer: issuer,
  clientId: client_id,
  redirectUri: 'https://audit-tool-331011.web.app/login/callback',
  scopes: ['openid', 'profile', 'email']
})

export { oktaAuth, oktaSignIn };
