<template>
  <div class="col-12">
    <div class="question-card mx-1 my-3 pb-3">
      <div class="px-3">
        <div class="row px-3 pt-4 pb-0">
          <div class="col-12">
            <p class="mb-0 question-title"><span class="font-weight-800 mb-0 question-number">{{ question.questionNumber }}.</span>{{ question.title }}</p>
            <hr>
            <div class="row mb-1 mt-3">
              <div>
                <div>
                  <question-button :color="color" v-for="index in 4" :question="question" :key="index" @selectResponse="answered" :selected-response="selectedResponse" :response-number="index" :response-text="question.responsesOptions[index].responseText"></question-button>
                </div>
              </div>
            </div>
            <b-form-textarea :disabled="!selectedResponse" @input="addComment" rows="3" class="mb-3 mt-2" v-model="comment" placeholder="Additional comments"></b-form-textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import QuestionResponse from '@/components/QuestionResponse.vue'

import QuestionButton from "./QuestionButton";
export default {
  name: "Question",
  components: {
    QuestionButton
  },
  props: {
    question: Object,
    highlightIncomplete: Boolean,
    color: String
  },
  data() {
    return {
      value: '0',
      data: ['0', '1', '2', '3', '4'],
      selectedResponse: null,
      comment: ''
    }
  },
  methods: {
    answered(responseQuestion, responseNumber) {
      this.selectedResponse = responseNumber
      this.$emit('answered', this.question.questionNumber, responseNumber)
      this.comment = ''
    },
    addComment() {
      this.$emit('commented', this.comment)
    },
    getStyle() {
      if (this.color === 'green') {
        return 'green-dot'
      } else if (this.color === 'blue') {
        return 'blue-dot'
      } else {
        return 'yellow-dot'
      }
    },
    getTrackStyle() {
      if (this.color === 'green') {
        return {backgroundColor: '#78d300'}
      } else if (this.color === 'blue') {
        return {backgroundColor: '#1aa3c0'}
      } else {
        return {backgroundColor: '#FF8000'}
      }
    }
  },
  computed: {
    incompleteAnswer() {
      if (this.highlightIncomplete && this.value === "0") {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>

.hide-zero  {
  display: none;
}

.incomplete {
  color: red;
  font-weight: bold;
}

.question-response {
  font-size: 20px;
  margin: 0 !important;
}

.question-response-container {
  height: 120px;
  line-height: 120px;
  text-align: center;
  background: #FAFAFA;
  margin: 1em 0;
}

.question-response {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.response-number {
  font-weight: bold;
  text-align: center;
  line-height: 150px;
  border-radius: 100%;
}

.response-number span {
  display: inline-block;
  vertical-align: middle;
  line-height: 48px;
}

.question-divider {
  border: solid #dadada 1px;
  opacity: 1;
}

.question-number {
  font-size: 26px;
  font-weight: 800;
  margin-right: 1em;
}

.disabled-question {
  opacity: 0.4;
}

.question-title {
  font-size: 20px;
}

.custom-rail {

}

.yellow-dot {
  width: 25px;
  height: 25px;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 100%;
  background-color: #FF8000FF;
  transition: all .3s;
}

.blue-dot {
  width: 25px;
  height: 25px;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 100%;
  background-color: #1aa3c0;
  transition: all .3s;
}

.green-dot {
  width: 25px;
  height: 25px;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 100%;
  background-color: #78d300;
  transition: all .3s;
}

.question-card {
  border-radius: 5px;
  background: white;
}

</style>