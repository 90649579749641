<template>
  <div class="question-button" :class="this.getStyle()" @click="selectResponse">
    {{ responseText }}
  </div>
</template>

<script>
export default {
  name: "QuestionButton",
  props: {
    responseText: String,
    responseNumber: Number,
    selectedResponse: Number,
    question: Object,
    color: String
  },
  methods: {
    selectResponse() {
      this.$emit('selectResponse', this.question.questionNumber, this.responseNumber, this.responseText)
    },
    getStyle() {
      if (this.responseNumber === this.selectedResponse) {
        if (this.color === 'green') {
          return 'selected-green'
        } else if (this.color === 'blue') {
          return 'selected-blue'
        } else {
          return 'selected-orange'
        }
      }
    }
  }
}
</script>

<style scoped>

.question-button {
  padding: 1em;
  text-align: left;
  background: #FAFAFA;
  margin: 1em 0 1.5em 2em;
  border-radius: 10px;
  font-size: 20px;
}

.question-button:hover {
  cursor: pointer;
}

.selected-green {
  background-color: #78d300;
  color: white;
  font-weight: bold;
  letter-spacing: -0.025em;
}

.selected-blue {
  background-color: #1aa3c0;
  color: white;
  font-weight: bold;
  letter-spacing: -0.025em;
}

.selected-orange {
  background-color: #FF8000;
  color: white;
  font-weight: bold;
  letter-spacing: -0.025em;
}

</style>