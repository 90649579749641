<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10 mt-5">
        <div class="card-group">
          <div class="card py-5 d-md-down-none login-card">
            <h2 class="text-center my-4">Signing Out...</h2>
            <div class="card-body text-center">
              <div class="card-body p-0 mt-3 text-left">
                <img class="m-auto" src="@/assets/m2_logo.png" width="200">
                <p>from</p>
                <img class="m-auto" src="@/assets/mediacom-logo.png" width="100">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Logout',
  mounted: function () {
    const url = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/accounts/logout/`
    const token = localStorage.getItem("token")
    return axios.get(url, {headers: {Authorization: `Bearer ${JSON.parse(token)}`}})
        .then(() => {
            localStorage.removeItem("token")
            this.$router.push({ name: "LoginLanding" })
        })
        .catch(error => {
            if (error.response.status === 401) {
                console.log("Token is invalid")
            } else {
                console.log(error);
            }
        })
    
  }
}
</script>
