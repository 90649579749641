<template>
  <div class="home">
    <div id="nav" class="bg-white">
      <div class="row">
        <div class="col text-left">
          <img src="@/assets/m2_logo.png" width="150" class="mt-0">
        </div>
        <div class="col nav-right mt-3">
          <router-link class="nav-link" to="/audit">Audit</router-link>
          <router-link class="nav-link" to="/reports">Reports</router-link>
          <div @click="logOut" class="nav-logout">Sign Out</div>
        </div>
      </div>
    </div>
    <div v-if="connectionLost" class="connection-lost">
      <p class="mb-0">Your session has expired</p>
      <span>Please <span class="logout-link" @click="logOut">login</span> again to continue</span>
    </div>
    <div class="container-fluid mt-3">
      <ReportList :reports="reports"></ReportList>
    </div>
  </div>
</template>

<script>
import ReportList from '@/components/ReportList.vue'
import axios from "axios";

export default {
  name: 'Reports',
  components: {
    ReportList
  },
  data() {
    return {
      posts: [],
      reports: [],
      connectionLost: false
    };
  },
  methods: {
    async getData() {
      // let self = this
      try {
        const response = await this.$http.get(
            "http://jsonplaceholder.typicode.com/posts"
        );
        // JSON responses are automatically parsed.
        this.posts = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    logOut() {
      this.$store.commit("logout")
      this.$router.push({ name: "Logout" })
    },
    refreshToken() {
      const refresh = localStorage.getItem("refresh")
      const refreshUrl = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/token/refresh/`
      const payload = {"refresh": refresh.substring(1, refresh.length - 1)}
      return axios.post(refreshUrl, payload).then((response) => {
        this.$store.commit("updateToken", response.data.access);
        this.$store.commit("updateRefresh", response.data.refresh);
      }).catch((error) => {
        console.log(error);
        this.$emit('connection-lost')
        this.$store.commit("logout")
        this.$router.push({ name: "Login" })
      })
    }
  },
  async created(){
    const url = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/responses/`
    const token = localStorage.getItem("token")
    return axios.get(url, {headers: {Authorization: `Bearer ${JSON.parse(token)}` }}).then((response) => {
      this.reports = response.data
    }).catch(error => {
      if (error.response.status === 401) {
        this.refreshToken()
      } else {
        console.log(error);
        this.connectionLost = true
      }
    })
  },
}
</script>
