import Vue from 'vue';
import Router from 'vue-router';

import LoginLandingComponent from '@/components/LoginLanding'
import LoginComponent from '@/components/Login'
import LoginCallbackComponent from '@/components/LoginCallback'
import LogoutComponent from '@/components/Logout'

import Audit from '../views/Audit.vue'
import Settings from '../views/Settings.vue'
import Reports from '../views/Reports.vue'

import store from '../store';

const requireAuthenticated = (to, from, next) => {
  const token = localStorage.getItem("token")
  if (!token && !store.getters['isAuthenticated']) {
    next('/login_landing');
  } else {
    next();
  }
};



Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'LoginLanding',
      path: '/',
      component: LoginLandingComponent,
    },
    {
      path: '/audit',
      name: 'Audit',
      component: Audit,
      beforeEnter: requireAuthenticated,
    },
    {
      name: 'Login',
      path: '/login',
      component: LoginComponent
    },
    {
      path: '/login/callback',
      component: LoginCallbackComponent
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      beforeEnter: requireAuthenticated,
    },
    {
      path: '/reports',
      name: 'Reports',
      component: Reports,
      beforeEnter: requireAuthenticated
    },
    {
      path: '/logout',
      name: 'Logout',
      component: LogoutComponent
    },
    {
      path: '*', 
      redirect: '/' 
    } // catch all use case
  ]
});
