<template>
  <div class="home">
    <div id="nav" class="bg-white">
      <div class="row">
        <div class="col text-left">
          <img src="@/assets/m2_logo.png" width="150" class="mt-0">
        </div>
        <div class="col nav-right mt-3">
          <router-link class="nav-link" to="/audit">Audit</router-link>
          <router-link class="nav-link" to="/reports">Reports</router-link>
          <div @click="logOut" class="nav-logout">Sign Out</div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Settings',
  components: {

  },
  methods: {
    logOut() {
      this.$store.commit("logout")
      this.$router.push({ name: "Logout" })
    }
  }
}
</script>
