<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10 mt-5">
        <div class="card-group">
          <div class="card py-5 d-md-down-none login-card">
            <h2 class="text-center my-4">Loading...</h2>
            <div class="card-body text-center">
              <div class="card-body p-0 mt-3 text-left">
                <img class="m-auto" src="@/assets/m2_logo.png" width="200">
                <p>from</p>
                <img class="m-auto" src="@/assets/mediacom-logo.png" width="100">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import axios from 'axios';
  export default {
    name: 'LoginCallback',
    methods: {
      decodeToken(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
      }
    },
    async mounted() {
      // check for tokens from redirect
      if (location.hash) {
        // convert url hash parameters to json object
        let tokenInfo = JSON.parse('{"' + decodeURI(location.hash.substring(1).replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}')
        this.$store.commit("updateToken", tokenInfo.access_token);
        this.$store.commit("setAuthUser", {
          authUser: tokenInfo,
          isAuthenticated: true,
        });
        const token = localStorage.getItem("token")
        const url = 'https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/accounts/login/'
        return axios.get(url, {headers: {Authorization: `Bearer ${JSON.parse(token)}`}})
            .then(() => {
                this.$router.push({ name: "Audit" })
            })
            .catch(error => {
                if (error.response.status === 401) {
                    console.log("Token is invalid")
                    this.$router.push({ name: "Login" })
                } else {
                    console.log(error);
                }
            })
      }
    }
  }
</script>
