<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10 mt-5">
        <div class="card-group">
          <div class="card py-5 d-md-down-none login-card">
            <div class="card-body text-center">
              <div class="card-body p-0 mt-3 text-left">
                <div style="width: 100%;max-width:600px;margin: auto">
                  <img class="m-auto" src="@/assets/m2_logo.png" width="75%">
                  <p>from</p>
                  <img class="m-auto" src="@/assets/mediacom-logo.png" width="170">
                </div>
              </div>
            </div>
            <hr>
            <div class="text-center mt-4">
              <button v-on:click="redirect()" class="btn-okta">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="20" style="margin-top: -4px;margin-right: 5px" viewBox="0 0 66 65" fill="#fff" fill-rule="evenodd" stroke="#000" stroke-linecap="round" stroke-linejoin="round"><use xlink:href="#A" x=".5" y=".5"/><symbol id="A" overflow="visible"><path d="M32 0C14.37 0 0 14.267 0 32s14.268 32 32 32 32-14.268 32-32S49.63 0 32 0zm0 48c-8.866 0-16-7.134-16-16s7.134-16 16-16 16 7.134 16 16-7.134 16-16 16z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"/></symbol></svg>
                Login with Okta
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LoginLanding',
      methods : {
          redirect(){
              this.$router.push({ name: "Login" })
          }
      }
    }
</script>

<style lang="scss" scoped>

.btn-mediacom {
  color: #fff;
  background-color: #f2005a;
  border-color: #f2005a;
}

.btn-mediacom:hover {
  color: #fff;
  background-color: #ce024e;
  border-color: #ce024e;
}

.btn-okta {
  background: #3173bc;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: 800;
  text-transform: uppercase;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);

</style>







