<template>
  <div class="text-left col-12 p-0">
    <p v-if="questionGroup.groupTitle" class="question-title" :class="this.getStyle()">{{ questionGroup.groupTitle }}</p>
    <div class="row mx-0 my-3 px-3">
      <template v-for="question in questionGroup.questions">
        <question :color="color" :highlightIncomplete="highlightIncomplete" :question="question" :key="question.questionNumber" @answered="setAnswer" @commented="setComment"></question>
      </template>
    </div>
  </div>
</template>

<script>
import Question from '@/components/Question.vue'

export default {
  name: "QuestionGroup",
  components: {
    Question
  },
  props: {
    questionGroup: Object,
    questions: Array,
    highlightIncomplete: Boolean,
    color: String
  },
  data() {
    return {
      activeResponse: '',
      activeQuestion: ''
    }
  },
  methods: {
    setAnswer(question, response) {
      this.activeResponse = question
      this.activeQuestion = response
      this.$emit('answered', question, response)
    },
    setComment(comment) {
      this.$emit('commented', this.activeResponse, this.activeQuestion, comment)
    },
    getStyle() {
      if (this.color === 'green') {
        return 'header-green'
      } else if (this.color === 'blue') {
        return 'header-blue'
      } else {
        return 'header-yellow'
      }
    }
  }
}
</script>

<style scoped>

.question-title {
  padding: 0.3em 1.3em;
  color: white;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  margin: 0em;
}

.question-divider {
  border: solid #141414 1px;
  opacity: 1;
}

.question-card {
  border-radius: 5px;
  background: white;
}

.header-blue {
  color: white;
  background: #1aa3c0;
  font-size: 18px;
}

.header-green {
  color: white;
  background: #78d300;
  font-size: 18px;
}

.header-yellow {
  color: white;
  background: #FF8000FF;
  font-size: 18px;
}

</style>