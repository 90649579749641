import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import session from "../api/session";

Vue.use(Vuex);

// Make Axios play nice with Django CSRF
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export default new Vuex.Store({
  state: {
    authUser: {},
    isAuthenticated: false,
    jwt: localStorage.getItem("token"),
    endpoints: {
      // TODO: Remove hardcoding of dev endpoints
      obtainJWT: "https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/token/",
      refreshJWT: "https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/token/",
      baseUrl: "https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/token/",
    },
  },

  // actions: {
  //   initialize() {
  //     const token = localStorage.getItem("token");
  //   }
  // },

  getters: {
    isAuthenticatedL: state => !!state.isAuthenticated,
  },

  mutations: {
    setAuthUser(state, { authUser, isAuthenticated }) {
      Vue.set(state, "authUser", authUser);
      Vue.set(state, "isAuthenticated", isAuthenticated);
    },
    logout(state) {
      localStorage.removeItem("token");
      delete session.defaults.headers.Authorization;
      state.authUser = null
      state.isAuthenticated= false
    },
    updateToken(state, newToken) {
      localStorage.setItem("token", JSON.stringify(newToken));
      state.jwt = newToken;
    },
    updateRefresh(state, newToken) {
      localStorage.setItem("refresh", JSON.stringify(newToken));
      state.jwt = newToken;
    },
    updateUser(state, user) {
      localStorage.setItem("user_id", user);
      state.user_id = user;
    },
    removeToken(state) {
      localStorage.removeItem("token");
      state.jwt = null;
    },
  },
});