<template>
  <div class="container-fluid">
    <div class="row pb-3">


      <div class="col px-1" style="padding-right: 0 !important;margin-right: -1px !important;">

        <div v-if="this.companyAndProductSet">
          <p class="mb-2 select-header">Company</p>
          <p class="py-2">{{this.selectedCompany}}</p>
        </div>

        <div v-else>
          <p class="mb-2 select-header">Search</p>
          <b-form-input @update="searchProducts" v-model="productSearch" placeholder="Search for a company or product"></b-form-input>
        </div>

      </div>

      <div class="col px-1">
        <div v-if="this.companyAndProductSet">
          <p class="mb-2 select-header">Industry</p>
          <p class="py-2" v-if="selectedIndustry">{{this.selectedIndustry}}</p>
          <p class="py-2" v-else-if="this.industryListLoading">Loading Industries...</p>
          <b-form-select v-else-if="this.industryListLoading === false && this.companyAndProductSet" v-model="selectedIndustry" class="w-100 p-2 dropdown-select" :disabled="this.industryListDisabled" @change="getMarkets" :options="this.industryList"></b-form-select>
        </div>
      </div>

      <div class="col px-1">
        <div v-if="selectedIndustry">
          <p class="mb-2 select-header">Market</p>
          <p class="py-2" v-if="this.marketLoading">Loading Markets...</p>
          <b-form-select v-else-if="this.marketLoading === false && this.selectedIndustry" class="w-100 p-2 dropdown-select" @change="setSelection" v-model="selectedMarket" :options="this.marketList"></b-form-select>
        </div>
      </div>

    </div>

    <div class="row pb-3" v-if="this.productSearch.length >= 2 && this.showProductTable">
      <div class="row mx-0 my-3 px-3">
        <div class="col-12">
          <div class="bg-white product-table-container">
            <b-table @row-clicked="setCompanyAndProduct" :items="this.companyList" :busy="this.fetchingCompanies" :fields="this.productTableFields" hover sticky-header responsive>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>
              <template #cell(id)="data">
                {{ data.item.id }}
              </template>
              <template #cell(company)="data">
                {{ data.item.company }}
              </template>
              <template #cell(product)="data">
                {{ data.item.product }}
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ModelSelect } from 'vue-search-select'

import axios from 'axios';
import formData from '../data/formData.json'
// import companies from '../data/companies.json'

export default {
  name: "SelectContainer",
  components: {
    // ModelSelect
  },
  data() {
    return {
      showProductList: false,
      showProductTable: true,
      companyID: '',
      companyQuery: '',
      productSearch: '',
      formData: formData,
      fetchingCompanies: false,
      companyAndProductSet: false,
      selectedCompany: null,
      selectedProduct: null,
      selectedMarket: null,
      selectedIndustry: null,
      selectedSubIndustry: null,
      companyListLoading: false,
      productListLoading: false,
      industryListLoading: false,
      marketLoading: false,
      companyListDisabled: true,
      productListDisabled: true,
      industryListDisabled: true,
      subIndustryMarketDisabled: true,
      selectedCategory: null,
      showSelect: false,
      companyData: [],
      productData: [],
      industryData: [],
      subIndustryData: [],
      marketData: [],
      // companies: companies,
      companyList: [],
      productList: [
        {
          "value": null,
          "text": "Select a Product"
        },
        {
          "value": 'none',
          "text": "None"
        }
      ],
      productTable: [],
      productTableFields: [
        { key: 'id', label: 'ID', sortable: true, sortDirection: 'desc' },
        { key: 'company', label: 'Company', sortable: true, sortDirection: 'desc' },
        { key: 'product', label: 'Product', sortable: true, sortDirection: 'desc' },
      ],
      industryList: [
        {
          "value": null,
          "text": "Select an Industry"
        },
        {
          "value": 'none',
          "text": "None"
        }
      ],
      subIndustryList: [
        {
          "value": null,
          "text": "Select a Sub-Industry"
        },
        {
          "value": 'none',
          "text": "None"
        }
      ],
      marketList: [
        {
          "value": null,
          "text": "Select a Market"
        },
        {
          "value": 'none',
          "text": "None"
        }
      ]
    }
  },
  async created() {
    const url = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/`
    const token = localStorage.getItem("token")
    return axios.get(url, {headers: {Authorization: `Bearer ${JSON.parse(token)}`}}).then(() => {
    }).catch((error) => {
      if (error.response.status === 401) {
        this.refreshToken()
      } else {
        this.$emit('connection-lost')
        this.$store.commit("logout")
        this.$router.push({ name: "Login" })
      }
    })
  },
  watch: {
    selectedIndustry() {
      this.getMarkets()
      // const url = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/subindustries/?company_name=` + this.selectedCompany.value.name
      // const token = localStorage.getItem("token")
      // this.getMarkets()
      // this.marketLoading = true
      // return axios.get(url, {headers: {Authorization: `Bearer ${JSON.parse(token)}`}}).then((response) => {
      //   this.subIndustryData = response
      //   this.getSubIndustriesList()
      //   this.subIndustryMarketDisabled = false
      //   this.marketLoading = false
      // }).catch(error => {
      //   if (error.response.status === 401) {
      //     this.refreshToken()
      //   } else {
      //     console.log(error);
      //   }
      //   this.marketLoading = false
      // })
    }
  },
  methods: {
    fetchIndustries() {
      this.selectedIndustry = null
      const url = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/industries/?company_name=` + this.selectedCompany
      const token = localStorage.getItem("token")
      this.industryListLoading = true
      return axios.get(url, {headers: {Authorization: `Bearer ${JSON.parse(token)}`}}).then((response) => {
        this.industryData = response
        this.getIndustriesList()
        this.industryListDisabled = false
        this.industryListLoading = false
      }).catch(error => {
        if (error.response.status === 401) {
          this.refreshToken()
        } else {
          console.log(error);
        }
        this.industryListLoading = false
      })
    },
    setCompanyAndProduct(item) {
      this.showProductTable = false
      this.companyAndProductSet = true
      this.selectedCompanyID = item.id
      this.selectedCompany = item.company
      this.selectedProduct = item.product
      this.fetchIndustries()
    },
    setCompany(company) {
      this.selectedCompany = company
    },
    searchProducts() {
      let self = this
      var delayTimer
      if (this.productSearch.length >= 2) {
        this.fetchingCompanies = true
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function() {
          const url = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/products/?q=` + self.productSearch
          const token = localStorage.getItem("token")
          return axios.get(url, {headers: {Authorization: `Bearer ${JSON.parse(token)}`}}).then((response) => {
            self.fetchingCompanies = false
            self.companyData = response
            self.getCompanyList()
            self.companyListDisabled = false
            self.companyListLoading = false
            self.showProductList = true
          }).catch(error => {
            self.fetchingCompanies = false
            if (error.response.status === 401) {
              this.refreshToken()
            } else {
              this.$emit('connection-lost')
              this.$store.commit("logout")
              this.$router.push({ name: "Login" })
            }
            self.showProductList = true
          })
        }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
      }
    },
    getMarkets() {
      const url = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/markets/`
      const token = localStorage.getItem("token")
      this.marketLoading = true
      return axios.get(url, {headers: {Authorization: `Bearer ${JSON.parse(token)}`}}).then((response) => {
        this.marketData = response
        this.marketLoading = false
        this.getMarketList()
      }).catch(error => {
        if (error.response.status === 401) {
          this.refreshToken()
        } else {
          console.log(error);
        }
        this.marketLoading = false
      })
    },
    refreshToken() {
      const refresh = localStorage.getItem("refresh")
      const refreshUrl = `https://ma2-audit-api-dev-ngxbllhxtq-nw.a.run.app/api/token/refresh/`
      const payload = {"refresh": refresh.substring(1, refresh.length - 1)}
      return axios.post(refreshUrl, payload).then((response) => {
        this.$store.commit("updateToken", response.data.access);
        this.$store.commit("updateRefresh", response.data.refresh);
      }).catch((error) => {
        console.log(error);
        this.$emit('connection-lost')
        this.$store.commit("logout")
        this.$router.push({ name: "Login" })
      })
    },
    setSelection() {
      this.$emit('selectionSet', this.selectedCompanyID, this.selectedCompany, this.selectedProduct, this.selectedIndustry, '', this.selectedMarket)
    },
    getCompanyList() {
      let companies = []
      for (let i = 0; i < this.companyData.data.length; i++) {
        const company = {
          id: this.companyData.data[i].company_id,
          company: this.companyData.data[i].company_name,
          product: this.companyData.data[i].product_name,
        }
        companies.push(company)
      }
      this.showSelect = true
      this.companyList = companies
    },
    getProductList() {
      let products = [
        {
          "value": null,
          "text": "Select a Product"
        },
        {
          "value": 'none',
          "text": "None"
        }
      ]
      for (let i = 0; i < this.productData.data.length; i++) {
        const product = {
          "value": {
            "name": this.productData.data[i].product_name,
            "id": this.productData.data[i].company_id
          },
          "text": this.productData.data[i].product_name
        }
        products.push(product)
      }
      this.showSelect = true
      this.productList = products
    },
    getProductTable() {
      let products = []
      // let products = [
      //   {
      //     "value": null,
      //     "text": "Select a Product"
      //   },
      //   {
      //     "value": 'none',
      //     "text": "None"
      //   }
      // ]
      for (let i = 0; i < this.productData.data.length; i++) {
        const product = {
          "id": this.productData.data[i].company_id,
          "product": this.productData.data[i].product_name,
          "company": this.productData.data[i].company_name
        }
        products.push(product)
      }
      this.showSelect = true
      this.productTable = products
    },
    getIndustriesList() {
      let industries = [
        {
          "value": null,
          "text": "Select an Industry"
        },
        {
          "value": 'none',
          "text": "None"
        }
      ]
      for (let i = 0; i < this.industryData.data.length; i++) {
        const industry = {
          "value": this.industryData.data[i].industry,
          "text": this.industryData.data[i].industry
        }
        industries.push(industry)
      }
      this.showSelect = true
      this.industryList = industries
    },
    getSubIndustriesList() {
      let subIndustries = [
        {
          "value": null,
          "text": "Select a Sub-Industry"
        },
        {
          "value": 'none',
          "text": "None"
        }
      ]
      for (let i = 0; i < this.subIndustryData.data.length; i++) {
        const subIndustry = {
          "value": this.subIndustryData.data[i].industry + ' - ' + this.subIndustryData.data[i].sub_industry,
          "text": this.subIndustryData.data[i].industry + ' - ' + this.subIndustryData.data[i].sub_industry,
        }
        subIndustries.push(subIndustry)
      }
      this.showSelect = true
      this.subIndustryList = subIndustries
    },
    getMarketList() {
      let markets = [
        {
          "value": null,
          "text": "Select a Market"
        },
        {
          "value": 'none',
          "text": "None"
        }
      ]
      for (let i = 0; i < this.marketData.data.length; i++) {
        const market = {
          "value": {
            "region": this.marketData.data[i].region,
            "country": this.marketData.data[i].country,
          },
          "text": this.marketData.data[i].region + ' - ' + this.marketData.data[i].country
        }
        markets.push(market)
      }
      this.showSelect = true
      this.marketList = markets.sort()
    }
  }
}
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.product-table-container {
  margin-top: 1.5em;
  border-radius: 5px;
  background: white;
  padding: 2em;
}

.product-table-container > div {
  max-height: 60vh;
  overflow: scroll;
}

.select-header {
  color: #f2005a;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

.dropdown-select {
  padding: 0 0 4px;
  background: none;
  border: 1px solid rgba(60, 60, 60, .26);
  border-radius: 4px;
  white-space: normal;
}

</style>