<template>
  <div class="home">
    <div id="nav" class="bg-white">
      <div class="row">
        <div class="col text-left">
          <img src="@/assets/m2_logo.png" width="150" class="mt-0">
        </div>
        <div class="col nav-right mt-3">
          <router-link class="nav-link" to="/audit">Audit</router-link>
          <router-link class="nav-link" to="/reports">Reports</router-link>
          <div @click="logOut" class="nav-logout">Sign Out</div>
        </div>
      </div>
      <hr>
    </div>
    <div v-if="connectionLost" class="connection-lost">
      <p class="mb-0">Your session has expired</p>
      <span>Please <span class="logout-link" @click="logOut">login</span> again to continue</span>
    </div>
    <div>
      <div v-if="!connectionLost" class="bg-white select-container text-left px-2">
        <transition name="fade" mode="out-in">
          <div v-if="showForm" class="text-left pt-1 pb-2 ml-4">
            <p class="mb-0 mr-5 btn-clear" @click="clearSelections">Click to generate a new form</p>
            <p class="mb-2">Showing Audit Form for:</p>
            <p class="selectedForm"><span>Company: <strong>{{ this.selectedCompany }}</strong></span> | <span>Industry: <strong>{{ this.selectedIndustry }}</strong></span> | <span v-if="this.selectedSubIndustry">Sub-Industry: <strong>{{ this.selectedSubIndustry }}</strong></span> <template v-if="this.selectedSubIndustry">|</template> <span>Market: <strong>{{ this.selectedMarket.region }} - {{ this.selectedMarket.country }}</strong></span></p>
          </div>
          <SelectContainer @connection-lost="showConnectionLost()" v-else @selectionSet="this.setSelection"></SelectContainer>
        </transition>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <FormContainer
          :selectedCompanyId="selectedCompanyId"
          :selectedCompany="selectedCompany"
          :selectedProduct="selectedProduct"
          :selectedIndustry="selectedIndustry"
          :selectedSubIndustry="selectedSubIndustry"
          :selectedMarket="selectedMarket"
          v-if="showForm"
      ></FormContainer>
    </transition>
  </div>
</template>

<script>
import SelectContainer from '@/components/SelectContainer.vue'
import FormContainer from '@/components/FormContainer.vue'

export default {
  name: 'Audit',
  data() {
    return {
      showForm: false,
      connectionLost: false,
      selectedCompany: null,
      selectedCompanyId: null,
      selectedProduct: null,
      selectedIndustry: null,
      selectedSubIndustry: null,
      selectedMarket: null
    }
  },
  components: {
    FormContainer,
    SelectContainer
  },
  methods: {
    showConnectionLost() {
      this.connectionLost = true
    },
    clearSelections() {
      this.showForm = false
      this.selectedCompany = null
      this.selectedProduct = null
      this.selectedIndustry = null
      this.selectedSubIndustry = null
      this.selectedMarket = null
    },
    setSelection(selectedCompanyId, selectedCompany, selectedProduct, selectedIndustry, selectedSubIndustry, selectedMarket) {
      this.selectedCompanyId = selectedCompanyId
      this.selectedCompany = selectedCompany
      this.selectedProduct = selectedProduct
      this.selectedIndustry = selectedIndustry
      this.selectedSubIndustry = selectedSubIndustry
      this.selectedMarket = selectedMarket
      this.showForm = true
    },
    logOut() {
      this.$store.commit("logout")
      this.$router.push({ name: "Logout" })
    }
  }
}
</script>

<style scoped>

.selectedForm {
  color: #f2005a;
  font-weight: bold;
  margin-bottom: 0;
}

.selectedForm span {
  color: white;
  background: #021627;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.selectedForm span strong {
  text-transform: uppercase;
}

.btn-clear {
  font-size: 12px;
  float: right;
}

.btn-clear:hover {
  cursor: pointer;
  color: black;
}

.select-container {
  height: 90px;
}

</style>
